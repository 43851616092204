import React from 'react';

import { Loading } from '@alpine-iq/ui-library';
import { cssNum } from '@/helpers';

type Props = {
	fullPage?: boolean;
	padding?: string;
};
export default function Loader({ fullPage, ...props }: Props) {
	return (
		<div
			css={`
				position: relative;

				display: flex;
				justify-content: center;
				align-items: center;

				height: ${fullPage ? '100vh' : '100%'};
				width: 100%;

				padding: ${cssNum(props.padding || 'auto')};
			`}
		>
			<Loading {...props} />
		</div>
	);
}
